import Vue from 'vue';
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate);
import {validationMixin} from 'vuelidate';
import {required, sameAs, minLength, maxLength} from 'vuelidate/lib/validators';

import {mapActions, mapGetters, mapMutations} from "vuex";


export default {
  name: 'set-new-password',
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        password: '',
        password_confirmation: '',
        code: this.$route.query.code,
        email: this.$route.query.email
      },
      validationErrors: {
        password: [],
        password_confirmation: [],
      }
    }
  },
  validations: {
    payload: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },

    }
  },

  computed: {
    ...mapGetters({
      resetLoading: 'auth/resetLoading',
    }),
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
  },
  methods: {
    ...mapActions({
      send: `auth/SET_NEW_PASSWORD`
    }),
    ...mapMutations({
      showPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
      changePopupNewPassword: `auth/CHANGE_POPUP_NEW_PASSWORD`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.send(this.payload)
          .then(() => {
            this.changePopupNewPassword(false);
            this.$nextTick(()=>{
              this.changeContentPopup({
                text: 'Пароль успешно изменен!',
              });
            })
            setTimeout(()=> {
              this.changeShowContentPopup(true);
            },200)
            this.showPopup(true);
            this.$router.replace({'query': null});
          })
          .catch(error => {
            let formattedMessage = null;

            if (error && error.response) {
              if (error.response.data.message) {
                formattedMessage = error.response.data.message;
              }

              if (error.response.data.error) {
                formattedMessage = error.response.data.error.message;
              }

              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach(errorKey => {
                  error.response.data.errors[errorKey].forEach((line) => {
                    formattedMessage += `\r\n${line}`;
                  });
                });
              }
            }

            formattedMessage && this.$toasted.error(formattedMessage);
          });
      }
    }
  },
  destroyed() {

  }
}
